import React from 'react';
import { createRoot } from 'react-dom/client';
import { AuthProvider } from 'react-oidc-context';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { datadogRum } from '@datadog/browser-rum';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import AuthClient from './Authorization';
import FeaturesProvider from './contexts/Features.context';
import NotificationProvider from './contexts/Notification.context';
import RolesProvider from './contexts/Roles.context';
import { getEnv } from './utils/environment';
import Router from './Router';
import theme from './theme';
import './index.scss';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

const forgerockUri = window.location.origin.includes('one.cube.toyota.com')
  ? 'https://ep.fram.idm.toyota.com/openam/oauth2/realms/root/realms/dealerdaily'
  : 'https://ep.fram.qa.idm.toyota.com/openam/oauth2/realms/root/realms/dealerdaily';

const oidcConfig = {
  onSigninCallback: () => {
    const pathname = sessionStorage.getItem('cube-pathname') || '/';
    sessionStorage.removeItem('cube-pathname');
    window.history.replaceState(null, '', window.location.origin + pathname);
  },
  authority: forgerockUri,
  client_id: 'DSMETACT',
  redirect_uri: window.location.origin,
  scope: 'openid profile',
};

const env = getEnv();

const isProduction = process.env.NODE_ENV === 'production';

if (isProduction) {
  datadogRum.init({
    applicationId: 'aa067475-e24c-4cfb-ae90-3ac942e7eafe',
    clientToken: 'pub3d40ee8ffd95623c010d7415e19dd122',
    site: 'datadoghq.com',
    service: 'cubee2e-client-ui',
    env,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
  });

  datadogRum.setGlobalContextProperty('account_id', '091975348311');
  datadogRum.startSessionReplayRecording();
}

const queryClient = new QueryClient();

root.render(
  <AuthProvider {...oidcConfig}>
    <AuthClient>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <FeaturesProvider>
          <RolesProvider>
            <NotificationProvider>
              <QueryClientProvider client={queryClient}>
                <Router />
              </QueryClientProvider>
            </NotificationProvider>
          </RolesProvider>
        </FeaturesProvider>
      </ThemeProvider>
    </AuthClient>
  </AuthProvider>,
);
